<template>
  <b-form-group
    label="Grup"
    label-for="id_com_sparepart_stock_groups"
  >
    <validation-provider
      #default="{ errors }"
      name="Grup"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_com_sparepart_stock_groups"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Giriniz"
      >
        <template v-slot:option="option">
          <div v-if="option.main_stock_groups">
            {{ option.title }}
            <div>
              <small class="text-warning">{{ option.main_stock_groups }}</small>
            </div>
          </div>
          <div v-else>
            {{ option.title }}
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div v-if="option.main_stock_groups">
            {{ option.title }}
            <small class="text-warning">{{ option.main_stock_groups }}</small>
          </div>
          <div v-else>
            {{ option.title }}
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'SparepartsGroupsCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['sparepartStockGroups/dataList']
    },
    dataItem() {
      return this.$store.getters['sparepartStockCards/dataItem']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('sparepartStockGroups/getDataList', {
        select: [
          'com_sparepart_stock_groups.id AS id',
          'com_sparepart_stock_groups.title AS title',
          'main_stock_groups.title AS main_stock_groups',
        ],
        where: {
          'com_sparepart_stock_groups.id_com_sparepart_stock_groups !=': null,
        },
      })
    },
  },
}
</script>
