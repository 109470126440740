<template>
  <div>
    <b-card title="Veri Aktarımı">
      <b-row>
        <b-col>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col>
              <b-form-group
                label="Dosya Seçin"
                label-for="file"
              >
                <b-form-file
                  id="file"
                  accept=".xls, .xlsx"
                  browse-text="Dosya Seçin"
                  placeholder="Dosya Seçin"
                  @change="onChange"
                />
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-button
                variant="danger"
                href="https://inll.app/Medusa2/media/templates/yedekparca_sablon.xlsx"
                target="_blank"
              >
                <feather-icon icon="DownloadIcon" /> Şablon Dosyasını İndir
              </b-button>
            </b-col>
          </b-row>
          <b-form-group
            label="Çalışma Sayfası Seçiniz"
            label-for="sheets"
          >
            <xlsx-read :file="file">
              <xlsx-sheets>
                <template #default="{sheets}">
                  <v-select
                    id="sheets"
                    v-model="selectedSheet"
                    :options="sheets"
                    placeholder="Seçiniz"
                    :disabled="!file"
                  />
                </template>
              </xlsx-sheets>
            </xlsx-read>
          </b-form-group>
        </b-col>
        <b-col
          v-if="file"
          cols="12"
          md="12"
        >
          <div class="text-center">
            <b-button
              variant="primary"
              :disabled="uploading"
              @click="importDataSave"
            >
              <b-spinner
                v-if="uploading"
                small
              />
              <feather-icon
                v-if="!uploading"
                icon="EyeIcon"
              /> Kontrol Et
            </b-button>
            <b-button
              v-if="previewData.length > 0"
              variant="success"
              :disabled="transfer"
              class="ml-1"
              @click="saveImportData"
            >
              <b-spinner
                v-if="transfer"
                small
              />
              <feather-icon
                v-if="!transfer"
                icon="UploadIcon"
              /> Aktar ve Kayıtları Oluştur
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="selectedSheet && upload_status === null"
      no-body
    >
      <b-card-header>
        <b-card-title>Ön İzleme</b-card-title>
      </b-card-header>
      <div class="height-500 overflow-scroll">
        <div
          v-if="!tempData"
          class="text-center"
        >
          <b-spinner />
        </div>
        <b-table
          striped
          small
          hover
          :items="tempData"
          class="font-small-1"
        />
      </div>
      <xlsx-read
        :file="file"
        class="hidden"
      >
        <xlsx-table
          :sheet="selectedSheet"
        />
        <xlsx-json :sheet="selectedSheet">
          <template #default="{collection}">
            <div>
              {{ collection }}
              {{ getData(collection) }}
            </div>
          </template>
        </xlsx-json>
      </xlsx-read>
    </b-card>
    <b-card
      v-if="upload_status !== null"
      title="Aktarım Öncesi Kontrol"
    >
      <b-alert
        show
        :variant="upload_status === true ? 'warning':'danger'"
        class="mt-1"
      >
        <div class="alert-body text-center">
          {{ message }}
        </div>
      </b-alert>
      <template v-if="errData.length > 0">
        <b-alert
          v-for="(row,key) in errData"
          :key="key"
          show
          variant="danger"
          class="mt-1"
        >
          <div
            class="alert-body text-center"
          >
            <b>{{ row.line+1 }}.Satır Hata Mesajı: </b>{{ row.message }}
          </div>
        </b-alert>
      </template>
      <b-table-simple
        v-if="previewData.length > 0"
        small
        responsive
        bordered
      >
        <b-thead>
          <b-tr>
            <b-th>Sipariş No</b-th>
            <b-th>Sipariş Tarihi</b-th>
            <b-th>Tahmini Geliş Tarihi</b-th>
            <b-th>Parça No</b-th>
            <b-th>Parça Adı</b-th>
            <b-th>Sipariş Adedi</b-th>
            <b-th>Liste Satış Fiyatı</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(row,key) in previewData"
            :key="key"
          >
            <b-td>{{ row.order_no }}</b-td>
            <b-td>{{ moment(row.order_date).format('DD.MM.YYYY') }}</b-td>
            <b-td>{{ moment(row.arrival_date).format('DD.MM.YYYY') }}</b-td>
            <b-td>{{ row.part_no }}
              <div>
                <b-button
                  v-if="row.status === true"
                  size="sm"
                  variant="warning"
                  @click="showCardModal(key)"
                >
                  Parça Numarasını Düzelt
                </b-button>
              </div>
            </b-td>
            <b-td>{{ row.sparepart_title }}</b-td>
            <b-td>{{ row.quantity }}</b-td>
            <b-td>{{ row.list_price }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-modal
      v-model="cardModal.status"
      title="Yedek Parça Stok Kartı"
      centered
      no-close-on-esc
      no-enforce-focus
      size="lg"
    >
      <sparepart-import-stock-card-modal :callback-data="saveNewCard" />
      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="secondary"
            @click="cardModal.status = false"
          >
            İptal
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormFile, BTable, BSpinner, BButton, BAlert, BTableSimple, BTh, BTr, BThead, BTbody, BTd, BModal, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import {
  XlsxRead, XlsxTable, XlsxSheets, XlsxJson,
} from 'vue-xlsx'
import vSelect from 'vue-select'
import SparepartImportStockCardModal from '@/views/Spareparts/Orders/Components/SparepartEditModal.vue'

export default {
  name: 'ImportPage',
  components: {
    SparepartImportStockCardModal,
    BAlert,
    BSpinner,
    BTable,
    BFormFile,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    vSelect,
    BButton,
    BTableSimple,
    BTh,
    BTr,
    BThead,
    BTbody,
    BTd,
    BModal,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      uploading: false,
      transfer: false,
      tempData: null,
      previewData: [],
      errData: [],
      message: null,
      upload_status: null,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      cardModal: {
        status: false,
        index: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null
    },
    getData(colection) {
      this.tempData = colection
    },
    importDataSave() {
      this.submitStatus = true
      this.uploading = true
      this.$store.dispatch('sparepartOrders/batchInsert', {
        importData: this.tempData,
        id_com_sparepart_orders: this.$route.params.id,
      })
        .then(response => {
          if (response.status) {
            if (response.data.insertData) {
              this.previewData = response.data.insertData
              this.errData = response.data.errArr
              this.message = response.message
              this.upload_status = response.status
              if (this.errData.length > 0) {
                this.errData.forEach(err => {
                  const lineIndex = err.line // Line 1 ise index 0 olur
                  if (this.previewData[lineIndex]) {
                    this.previewData[lineIndex].status = true
                  }
                })
              }
            }
          }
          this.uploading = false
        })
    },
    saveImportData() {
      this.transfer = true
      this.submitStatus = true
      let error = false
      this.previewData.forEach((row, key) => {
        if (row.status) {
          error = true
        }
      })
      if (error === false) {
        this.$store.dispatch('sparepartOrders/saveBatch', {
          importData: this.previewData,
          id_com_sparepart_orders: this.$route.params.id,
        })
          .then(response => {
            if (response.status) {
              this.$swal({
                icon: 'success',
                title: this.$store.state.app.removeResultTitle,
                text: response.message,
                confirmButtonText: this.$store.state.app.removeResultClose,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push(`/sparepart-orders/edit/${this.$route.params.id}`)
            } else {
              this.$swal({
                icon: 'error',
                title: 'İşlem Başarısız',
                text: response.message,
                confirmButtonText: this.$store.state.app.removeResultClose,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
            this.transfer = false
          })
      } else {
        this.$swal({
          icon: 'error',
          title: 'İşlem Başarısız',
          text: 'Kontrol tablosunda hatalı veriler bulunuyor. Kayıt işlemi öncesinde düzeltilmelidir.',
          confirmButtonText: this.$store.state.app.removeResultClose,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.transfer = false
      }
    },
    showCardModal(index) {
      this.cardModal.status = true
      this.cardModal.index = index
    },
    saveNewCard(cardId, partNo, title) {
      this.previewData[this.cardModal.index].id_com_sparepart_stock_cards = cardId
      this.previewData[this.cardModal.index].sparepart_title = title
      this.previewData[this.cardModal.index].part_no = partNo
      delete this.previewData[this.cardModal.index].status
      this.cardModal.status = false
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
