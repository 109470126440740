<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <sparepart-stock-cards-part-no />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <sparepart-stock-cards-title />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <spareparts-brands-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <spareparts-groups-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import SparepartStockCardsPartNo from '@/views/Spareparts/StockCards/Elements/PartNoCard.vue'
import SparepartStockCardsTitle from '@/views/Spareparts/StockCards/Elements/TitleCard.vue'
import SparepartsBrandsCard from '@/views/Spareparts/StockCards/Elements/BrandCard.vue'
import SparepartsGroupsCard from '@/views/Spareparts/StockCards/Elements/GroupsCard.vue'

export default {
  name: 'SparepartStockCardsItemForm',
  components: {
    SparepartsGroupsCard,
    SparepartsBrandsCard,
    SparepartStockCardsTitle,
    SparepartStockCardsPartNo,

    BRow,
    BCol,
  },
  computed: {
    itemData() {
      return this.$store.getters['sparepartStockCards/dataItem']
    },
  },
}
</script>
