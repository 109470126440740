<template>
  <div>
    <b-row
      v-if="!newForm"
      class="d-flex justify-content-center align-items-center"
    >
      <b-col
        class="mb-1 mt-1"
      >
        <b-form-input
          id="searcg"
          v-model="search"
          placeholder="Parça No, Parça Adı ile ara.."
          @keydown.enter="getDataList"
        />
      </b-col>
      <b-col md="auto">
        <b-button
          variant="primary"
          @click="newCard"
        >
          <feather-icon icon="PlusIcon" />Yeni Ekle
        </b-button>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(title)="data">
            <div v-if="data.item.part_no">
              <small class="text-warning">{{ data.item.part_no }}</small>
            </div>
            <div>{{ data.item.title }}</div>
            <small
              v-if="data.item.main_stock_groups"
              class="text-primary"
            >{{ data.item.main_stock_groups }} /</small>
            <small
              v-if="data.item.sparepart_stock_groups"
              class="text-muted"
            > {{ data.item.sparepart_stock_groups }}</small>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              @click="callbackData(data.item.id,data.item.part_no,data.item.title)"
            >
              <feather-icon icon="CheckIcon" />Seç
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        cols="12"
        md="12"
      >
        <sparepart-stock-cards-item-form />
        <div>
          <b-button
            variant="primary"
            size="sm"
            @click="saveCardAndCallback"
          >
            <feather-icon icon="SaveIcon" /> Kartı Kaydet ve Seç
          </b-button>
          <b-button
            variant="secondary"
            class="ml-1"
            size="sm"
            @click="newForm = false"
          >
            <feather-icon icon="ChevronLeftIcon" />Kart seçimine dön
          </b-button>

        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BTable, BButton, BFormInput, BPagination,
} from 'bootstrap-vue'
import SparepartStockCardsItemForm from '@/views/Spareparts/StockCards/ItemForm.vue'

export default {
  name: 'SparepartImportStockCardModal',
  components: {
    BPagination,
    SparepartStockCardsItemForm,
    BFormInput,
    BButton,
    BTable,
    BRow,
    BCol,
  },
  props: {
    callbackData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      newForm: false,
      search: null,
      fields: [
        {
          key: 'title',
          label: 'Stok Kartı',
        },
        { key: 'control', label: '', thStyle: { width: '140px' } },
      ],
      dataQuery: {
        select: [
          'com_sparepart_stock_cards.id AS id',
          'com_sparepart_stock_cards.part_no AS part_no',
          'com_sparepart_stock_cards.title AS title',
          'com_sparepart_stock_groups.title AS sparepart_stock_groups',
          'main_stock_groups.title AS main_stock_groups',
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['sparepartStockCards/dataList']
    },
    dataCount() {
      return this.$store.getters['sparepartStockCards/dataCounts']
    },
    newStockCardForm() {
      return this.$store.getters['sparepartStockCards/dataItem']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 20
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      if (!this.search) {
        delete this.dataQuery.or_like
        this.$store.dispatch('sparepartStockCards/getDataList', this.dataQuery)
      } else {
        this.dataQuery.or_like = {
          'com_sparepart_stock_cards.title': this.search,
          'com_sparepart_stock_cards.part_no': this.search,
        }
        this.$store.dispatch('sparepartStockCards/getDataList', this.dataQuery)
      }
    },
    newCard() {
      this.$store.dispatch('sparepartStockCards/resetDataItem')
      this.newForm = true
    },
    saveCardAndCallback() {
      this.$store.dispatch('sparepartStockCards/saveData', this.newStockCardForm)
        .then(response => {
          if (response.status) {
            this.newForm = false
            this.callbackData(response.id, this.newStockCardForm.part_no, this.newStockCardForm.title)
          }
        })
    },
  },
}
</script>
